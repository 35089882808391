.home__figma {
    background-color: #eff6ff;
    padding: 0px 50px 0px 60px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: $extra-large-spacing;

    &__text {
        padding: $extra-large-spacing $small-spacing $extra-large-spacing 0 !important;

        h5 {
            max-width: 415px;
            color: color('800', 'blue');
            font-size: $font-size-3xl;
            line-height: $line-height-125;
            font-size: $font-size-3xl;
        }
        p {
            color: color('800', 'blue');
        }
    }

    img {
        display: block;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 120px;
        max-width: 100%;
    }

    a {
        line-height: normal;
        height: auto;
        min-height: $base-element-height;
        padding: $small-spacing 40px;
    }

    @include breakpoint('lg') {
        padding: 0 0 0 30px;

        img {
            left: 0;
        }
    }

    @include breakpoint('md') {
        padding: 0;
        &__text {
            padding-left: 30px !important;
        }
    }

    @include breakpoint('sm') {
        &__text {
            padding: $large-spacing !important;
            text-align: center;
            h5 {
                max-width: unset;
            }
        }
        &__image {
            max-height: 230px;

            img {
                position: relative;
                max-width: 100%;
                height: unset;
            }
        }
    }

    @media (max-width: 365px) {
        &__text {
            padding: $large-spacing $small-spacing !important;
        }

        a {
            padding: $small-spacing;
        }
    }
}

.home__free-svg {
    display: flex;
    gap: 30px;
    flex-direction: column;
    margin-bottom: 70px;
    margin-top: 30px;
    &__header {
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: flex-start;
        justify-content: space-between;
        > div {
            flex: 1;

            h5 {
                margin: 0;
                display: inline;
                line-height: $line-height-125 span {
                    color: color('500', 'green');
                }
            }
            p {
                width: 100%;
                margin: 0;
            }
        }
        > a {
            white-space: nowrap;
        }
        @include breakpoint('lg') {
            text-align: center;
            > div {
                h5 {
                    width: 100%;
                    display: inline-block;
                }
            }
            a {
                display: none;
            }
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 30px;
        color: color('800', 'gray');

        [class^='home__free-svg-'] {
            width: calc(20% - 24px);

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                padding: 50px 30px;
                transition: all 0.2s ease-in-out;
                &:hover {
                    box-shadow: 0px 4px 16px rgba(55, 73, 87, 0.05), 0px 7px 10px rgba(55, 73, 87, 0.05);
                    transform: translateY(-2px);
                }
            }
            p {
                margin: 0;
            }
            span {
                color: color('600', 'gray');
                font-size: 12px;
            }
            @include breakpoint('lg') {
                min-width: 272px;
            }
        }
        .home__free-svg-brands {
            img {
                background-color: color('100', 'blue');
            }
        }
        .home__free-svg-bold {
            img {
                background-color: color('100', 'purple');
            }
        }
        .home__free-svg-regular {
            img {
                background-color: color('100', 'yellow');
            }
        }
        .home__free-svg-solid {
            img {
                background-color: color('100', 'red');
            }
        }
        .home__free-svg-thin {
            img {
                background-color: color('100', 'green');
            }
        }
        .banner-footer {
            display: none;
            @include breakpoint('lg') {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}

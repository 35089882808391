.cards-products-home {
    margin: $base-spacing * 2 (-$small-spacing) $extra-large-spacing;
    > .row {
        margin: 0;
        align-items: stretch;
        justify-content: center;
        .card {
            padding: $small-spacing;
            align-items: stretch;
            a {
                font-weight: $font-weight-regular;
            }
            a:hover {
                .cards-products-home__item {
                    .cards-products-home__item__image {
                        img,
                        video {
                            transform: scale(1.05);
                        }
                    }
                }
            }
            .cards-products-home__item {
                margin: 0;
                height: 100%;
                flex-direction: column;
                .cards-products-home__item__text {
                    margin: 0;
                    text-align: center;
                    align-self: end;

                    h6 {
                        color: color('900', 'blue');
                    }
                    p {
                        margin: 0;
                        color: color('700', 'gray');
                    }
                }
                .cards-products-home__item__image {
                    border-radius: 8px;
                    overflow: hidden;
                    margin-bottom: 15px;

                    img,
                    video {
                        transition: all 0.2s ease-in-out;

                        width: 100%;
                        height: auto;
                        display: block;
                    }
                }
            }
        }
    }

    @include breakpoint('sm', 'min') {
        > .row {
            .card {
                .cards-products-home__item {
                    .cards-products-home__item__text {
                        text-align: left;
                    }
                }
            }
        }
    }
}

// Solución con aside Colecciones abierto

@include breakpoint('xl') {
    .collections.visible {
        ~ .container .cards-products-home {
            .card {
                width: 50%;
                .cards-products-home__item {
                    .cards-products-home__item__text {
                        width: 100%;
                        text-align: left;
                    }
                    .cards-products-home__item__image {
                        width: 100%;
                        height: unset;
                    }
                }
            }
        }
    }
}

.cards-products-home.circles {
    width: fit-content;
    margin: 40px auto 50px;
    display: grid;
    grid-template-columns: repeat(4, minmax(80px, 200px));
    gap: 30px;
    text-align: center;
    a {
        &:hover {
            .cards-products-home__item {
                .cards-products-home__item__image {
                    img,
                    video {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .cards-products-home__item {
        .cards-products-home__item__image {
            .circle {
                width: 120px;
                height: 120px;
                background: linear-gradient(218.58deg, color('500', 'green') 11.5%, color('100', 'green') 87.95%);
                border-radius: 50%;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .image {
                    width: 114px;
                    height: 114px;
                    border: 4px solid white;
                    border-radius: 50%;
                    margin: 0 auto;
                    z-index: 5;
                    overflow: hidden;
                    background: linear-gradient(110.49deg, color('75', 'gray') 26.28%, color('100', 'purple') 94.17%);
                }
            }
            img,
            video {
                transition: all 0.2s ease-in-out;
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .cards-products-home__item__text {
            color: color('800', 'gray');
            h6 {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                flex-wrap: wrap;
                font-family: var(--font-base);
                line-height: $line-height-160;
            }
            p {
                @supports (-webkit-line-clamp: 2) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-size: 12px;
                    line-height: $line-height-175;
                }
            }
        }
    }
    @include breakpoint('lg') {
        grid-template-columns: unset;
        grid-template-areas:
            'icons-circle icons-circle uicons-circle uicons-circle'
            'aicons-circle aicons-circle stickers-circle stickers-circle';
        .icons-circle {
            grid-area: icons-circle;
        }
        .uicons-circle {
            grid-area: uicons-circle;
        }
        .aicons-circle {
            grid-area: aicons-circle;
        }
        .stickers-circle {
            grid-area: stickers-circle;
        }
    }
    @include breakpoint('sm') {
        .icons-circle,
        .uicons-circle,
        .aicons-circle,
        .stickers-circle {
            max-width: 80px;
        }
        .cards-products-home__item {
            h6 {
                font-size: 13px;
            }
            .cards-products-home__item__image {
                .circle {
                    width: 64px;
                    height: 64px;
                    .image {
                        width: 60px;
                        height: 60px;
                        border: 2px solid white;
                    }
                }
            }
            .cards-products-home__item__text {
                p {
                    display: none;
                }
            }
        }
    }
}

// Solución con aside Colecciones abierto

@include breakpoint('xl') {
    .collections.visible {
        ~ .container .cards-products-home__item {
            h6 {
                font-size: 13px;
            }
            .cards-products-home__item__image {
                .circle {
                    width: 64px;
                    height: 64px;
                    .image {
                        width: 60px;
                        height: 60px;
                        border: 2px solid white;
                    }
                }
            }
            .cards-products-home__item__text {
                p {
                    display: none;
                }
            }
        }
    }
}

.uicons__banner {
    .container > & {
        margin-bottom: 50px;
    }
    h5 {
        color: color('900', 'blue');
    }
    background-color: color('50', 'gray');
    padding: 0px 50px 0px 60px;
    border-radius: 10px;
    overflow: hidden;

    &__text {
        padding: $extra-large-spacing $small-spacing $extra-large-spacing 0 !important;
    }

    img {
        display: block;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 100px;
        max-width: none;
    }

    @include breakpoint('lg') {
        padding-left: $large-spacing;

        img {
            left: 0;
        }
    }
}

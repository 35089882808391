.hero.hero--home {
    position: relative;
    text-align: center;
    color: color('800', 'gray');

    .content {
        max-width: 1024px;
        margin: auto;
        padding: 80px 100px 40px;

        > h1 {
            color: color('900', 'blue');
        }
        > h1.stickers {
            font-weight: $font-weight-bold;
            margin-bottom: $small-spacing;
        }
        > p {
            // font-size: 16px;
            font-weight: $font-weight-bold;
            color: color('900', 'blue');
        }
        > p.stickers {
            padding: 0 $large-spacing;
            color: color('800', 'gray');
            font-weight: $font-weight-regular;
        }

        .search-holder {
            .fake-search {
                height: 64px;
                line-height: 64px;

                .popover-button {
                    height: 64px;
                    line-height: 64px;
                }

                .popover-content {
                    border: 1px solid color('100', 'gray');
                    color: color('600', 'gray');
                    padding: $small-spacing $small-spacing;
                    ul {
                        hr {
                            width: 100%;
                            position: absolute;
                            left: 0px;
                        }
                        .switch {
                            line-height: 44px;
                        }
                    }
                }
                .popover-content.stickers {
                    border: none;
                    box-shadow: 0px 2px 8px color('800', 'gray', 0.06), 0px 15px 30px color('800', 'gray', 0.1);
                    color: color('800', 'gray');
                    min-width: 185px;
                    padding: 0 $small-spacing;
                    z-index: 10;
                }

                .radio-group {
                    height: auto;
                    line-height: normal;
                    margin: $small-spacing 0;
                }

                input {
                    padding: 0 $small-spacing;
                    height: 64px;
                    line-height: 64px;
                }

                .clean-search__button {
                    width: 64px;
                }

                button[type='submit'] {
                    width: 64px;
                    height: 64px;
                    line-height: 64px;
                }

                &.ios {
                    .awesomplete {
                        height: 64px;
                        display: flex;
                        align-items: center;
                        padding: $small-spacing + $extra-small-spacing 0;
                        cursor: text;

                        input {
                            height: 100%;
                            line-height: initial;
                        }

                        ul {
                            top: 64px;
                        }
                    }
                }
            }
        }

        .home__tags {
            margin-top: $small-spacing;
            margin-bottom: $small-spacing;
            font-size: $font-size-sm;

            ul {
                white-space: nowrap;
                overflow: auto;

                li {
                    display: inline-flex;

                    &:after {
                        content: ',';
                        display: inline-block;
                    }

                    &:last-child {
                        &:after {
                            content: '';
                            display: none;
                        }
                    }
                }
            }
        }
        .home__tags.stickers {
            margin-top: $base-spacing;
        }
    }

    @include breakpoint('md') {
        .content {
            > p.stickers {
                font-size: 16px;
                padding: 0 $large-spacing;
            }
        }
    }

    @include breakpoint('sm') {
        height: auto;

        ~ .container {
            .cards-products-home.circles {
                margin-top: 30px;
            }
        }

        .content.stickers {
            margin-top: 0;
            padding: $base-spacing + $small-spacing $base-spacing $base-spacing;

            > h1.stickers {
                margin-top: 0;
            }
            p.font-h3 {
                display: none;
            }
            > p.stickers {
                margin-top: 10px;
                padding: 0;
                margin-bottom: 20px;
            }
            .search-holder {
                .fake-search {
                    padding-left: 0;

                    .popover {
                        display: block;
                    }

                    .popover-button {
                        padding: 0 5px 0 10px;
                        font-size: 14px;
                    }

                    input {
                        font-size: 14px;
                    }

                    button.clean-search__button {
                        width: 44px;
                        padding: 0;
                        text-align: center;
                    }

                    button[type='submit'] {
                        width: 44px;
                    }
                }
            }
            .home__tags.stickers {
                margin-bottom: 0;
            }
        }
    }
}

.slider-tools {
    h3 {
        color: color('900', 'blue');
        font-size: $font-size-3xl;
        line-height: $line-height-125;
    }

    .wrapper {
        @include breakpoint('md') {
            padding: 0;
        }
    }

    header {
        @include breakpoint('md') {
            padding: 0 $base-spacing;
            margin: 0 0 40px 0;
        }

        p {
            font-size: 17px;
            color: color('700', 'gray');

            @include breakpoint('md') {
                display: none;
            }
        }
    }

    .slider-box {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include breakpoint('md') {
            flex-direction: column;
            align-items: flex-start;
        }

        &--items {
            width: 100%;
            max-width: 410px;

            @include breakpoint('xl') {
                max-width: 310px;
            }

            @include breakpoint('md') {
                max-width: 100%;
                display: flex;
                border-bottom: 1px solid color('75', 'gray');
                margin: 0 0 $base-spacing 0;
                overflow-y: auto;
                position: relative;
            }

            .item {
                position: relative;
                cursor: pointer;

                @include breakpoint('md') {
                    width: 100%;
                }

                &-title {
                    font-size: 20px;
                    font-weight: $font-weight-bold;
                    color: color('900', 'blue');
                    margin: $base-spacing $large-spacing $small-spacing $large-spacing;

                    @include breakpoint('md') {
                        font-size: 16px;
                        color: color('600', 'gray');
                        margin: 0;
                        padding: $small-spacing $base-spacing;
                        text-align: center;
                        white-space: nowrap;
                    }
                }

                &-text {
                    color: color('700', 'gray');
                    margin: 0 $large-spacing $base-spacing $large-spacing;
                    font-size: 17px;

                    @include breakpoint('md') {
                        display: none;
                    }
                }

                &-progress {
                    width: 100%;
                    height: 1px;
                    background: color('75', 'gray');
                    position: relative;

                    @include breakpoint('md') {
                        display: none;
                    }

                    &--state {
                        height: 1px;
                        width: 0;
                        background: color('500', 'green');
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        transition: 0.5s all;
                    }
                }

                i {
                    opacity: 0;
                    position: absolute;
                    top: 9px;
                    margin-left: -10px;
                    transition: 0.5s all;

                    @include breakpoint('md') {
                        display: none;
                    }
                }

                &.active {
                    .item-title {
                        color: color('500', 'green');
                    }

                    i {
                        opacity: 1;
                        margin-left: 0;
                        transition: 0.5s all;
                    }

                    @include breakpoint('md') {
                        .item-title {
                            color: color('900', 'gray');
                            border-bottom: 1px solid color('900', 'gray');
                        }
                    }
                }
            }
        }

        &--images {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            max-height: 590px;
            margin: 0 0 0 30px;

            @include breakpoint('md') {
                justify-content: center;
                margin: 0;
                padding: 0 $base-spacing;
            }

            picture {
                width: 100%;
                height: 590px;
                position: relative;

                @include breakpoint('md') {
                    height: 400px;
                }

                @include breakpoint('sm') {
                    height: 300px;
                }

                img {
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    object-fit: contain;

                    animation: fadeOut 0.5s;

                    &.active {
                        opacity: 1;

                        animation: fadeIn 0.5s;
                    }
                }
            }
        }

        &--items-mobile {
            display: none;
            width: 100%;
            padding: 0 20px;

            @include breakpoint('md') {
                display: block;
                text-align: center;
            }

            .item-text {
                display: none;
                margin: 0 0 20px 0;

                @include breakpoint('sm') {
                    font-size: 14px;
                }

                &.active {
                    display: block;
                }
            }
        }

        .mobile-gradient {
            &-prev {
                display: none;
                width: 45px;
                height: 45px;
                position: absolute;
                left: 0;
                background: rgb(255, 255, 255);
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

                &.active {
                    display: block;
                }
            }

            &-next {
                display: none;
                width: 45px;
                height: 45px;
                position: absolute;
                right: 0;
                background: rgb(255, 255, 255);
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

                &.active {
                    display: block;
                }
            }
        }
    }
}

.whats-new-section {
    background: color('50', 'gray');
    padding: $extra-large-spacing 20px 40px;
    border-radius: 10px;
    .whats-new-section__text {
        text-align: center;
        margin-bottom: $large-spacing;
        padding: 0 $base-spacing * 2;
        > h5 {
            color: color('900', 'blue');
        }
        > p {
            color: color('700', 'gray');
        }
        > h5,
        p {
            padding-right: 0;
        }
    }
    &__cards {
        > div {
            justify-content: center;
        }
        .whats-new-section__item {
            display: flex;
            flex-direction: column;
            background: #fff;
            width: 270px;
            height: 235px;
            padding: $small-spacing;
            box-shadow: 0px 1px 4px color('800', 'gray', 0.06), 0px 10px 20px color('800', 'gray', 0.1);
            border-radius: 8px;
            color: color('800', 'gray');
            transition: all 0.2s ease-in-out;
            margin-bottom: $base-spacing;
            .image-container.lzy {
                height: 100%;
                border-radius: 6px;
                background-size: cover;
                background-position: center;
                background-image: none;
            }
            p {
                margin: $small-spacing 0;
            }
        }
        .whats-new-section__item:hover {
            box-shadow: 0px 4px 16px color('800', 'gray', 0.1), 0px 25px 50px color('800', 'gray', 0.15);
            transform: translateY(-2px);
        }

        .whats-new-section__item.figma-plugin-v2 {
            .image-container.lzy.lazyload--done {
                background-image: url('~Media/home/whats-new-section/figma-plugin-v2-min.png');
            }
        }
        .whats-new-section__item.filters-redesign {
            .image-container.lzy.lazyload--done {
                background-image: url('~Media/home/whats-new-section/filters-redesign-min.png');
            }
        }
        .whats-new-section__item.smart-match {
            .image-container.lzy.lazyload--done {
                background-image: url('~Media/home/whats-new-section/smart-match-prev.png');
            }
        }
    }

    @include breakpoint('xl', 'min') {
        .whats-new-section__text {
            text-align: left;
            margin-bottom: 0;
            padding: 0 $base-spacing 0 $base-spacing * 3;
            > h5,
            p {
                padding-right: $base-spacing;
            }
            h5 {
                font-size: $font-size-3xl;
                line-height: $line-height-125;
            }
        }

        .whats-new-section__cards {
            .whats-new-section__item {
                margin-bottom: 0;
                width: 255px;
            }
        }
    }

    @include breakpoint('xl', 'min') {
        padding: 50px $base-spacing 40px 0;
    }

    @include breakpoint('lg') {
        &__cards {
            .whats-new-section__item:last-child {
                display: none;
            }
        }
    }
}
// Solución aside Colecciones abierto
.collections-visible {
    .whats-new-section {
        &__text {
            width: 100%;
            text-align: center;
            margin-bottom: $large-spacing;
        }
        &__cards {
            width: 100%;
            > div {
                justify-content: center;
            }
        }
    }
    @include breakpoint('lg', 'min') {
        .whats-new-section {
            &__cards {
                .whats-new-section__item:last-child {
                    display: none;
                }
            }
        }
    }

    @include breakpoint('xl', 'min') {
        .whats-new-section {
            &__cards {
                .whats-new-section__item:last-child {
                    display: flex;
                }
            }
        }
    }
}
